import React from 'react'
import { FaCheck } from 'react-icons/fa6'
import { BiLoaderAlt } from 'react-icons/bi'
function StakeProgress({ step = 0 }) {
    return (
        <div className="w-2/3 flex flex-col justify-start rounded-md p-2 leading-5 text-gray-400">
            <div className="flex flex-row items-center ">
                <div className="w-[24px]">
                    {step == 1 ? (
                        <BiLoaderAlt className="animate-spin " />
                    ) : step > 1 ? (
                        <FaCheck className=" text-green-500" />
                    ) : null}
                </div>
                <div className={`${step > 0 ? 'text-black' : ''}`}>
                    Check JIBJIB Allowance
                </div>
            </div>
            <div className="flex flex-row items-center ">
                <div className="w-[24px]">
                    {step == 2 ? (
                        <BiLoaderAlt className="animate-spin " />
                    ) : step > 2 ? (
                        <FaCheck className=" text-green-500" />
                    ) : null}
                </div>
                <div className={`${step > 1 ? 'text-black' : ''}`}>
                    Approve JIBJIB
                </div>
            </div>
            <div className="flex flex-row items-center ">
                <div className="w-[24px]">
                    {step == 3 ? (
                        <BiLoaderAlt className="animate-spin " />
                    ) : step > 3 ? (
                        <FaCheck className=" text-green-500" />
                    ) : null}
                </div>
                <div className={`${step > 2 ? 'text-black' : ''}`}>
                    Confirm Stake JIBJIB
                </div>
            </div>
        </div>
    )
}

export default StakeProgress
