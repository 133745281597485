import React, { useEffect, useRef } from 'react'

const Jibjib = () => {
    const containerRef = useRef(null) // Using ref to refer to the container

    // Function to handle creation and animation of JIB text
    const createJib = () => {
        if (containerRef.current) {
            const container = containerRef.current

            // Create the first "JIB" text element
            const text1 = document.createElement('span')
            text1.textContent = 'JIB'
            text1.style.position = 'absolute'
            text1.style.fontSize = `${getRandomSize()}px`
            text1.style.opacity = '0'
            text1.style.animation = 'fadeInOut 2s forwards'

            // Create the second "JIB" text element
            const text2 = document.createElement('span')
            text2.textContent = 'JIB'
            text2.style.position = 'absolute'
            text2.style.fontSize = `${getRandomSize() * 3}px`
            text2.style.opacity = '0'
            text2.style.animation = 'fadeInOut 2s forwards'

            // Positioning elements randomly within the container
            const { offsetWidth: width, offsetHeight: height } = container
            const x = Math.random() * (width - 100) // Adjust 100 based on your needs
            const y = Math.random() * (height - 40) // Adjust 40 based on your needs
            text1.style.left = `${x}px`
            text1.style.top = `${y}px`
            text2.style.left = `${x}px`
            text2.style.top = `${y}px`

            // Append elements to the container
            container.appendChild(text1)
            setTimeout(() => container.appendChild(text2), 500) // Delay appending text2

            // Remove the elements after animation
            setTimeout(() => text1.remove(), 2000)
            setTimeout(() => text2.remove(), 1000)
        }
    }

    // Random size generator for text
    const getRandomSize = () => Math.floor(Math.random() * 20) + 15

    useEffect(() => {
        const intervalId = setInterval(createJib, 1000) // Adjust time as needed for frequency of new "JIB"s

        return () => clearInterval(intervalId)
    }, [])

    return (
        <div
            className="border-0"
            ref={containerRef}
            style={{ position: 'relative', width: '400px', height: '100px' }}
        >
            {/* This div acts as the container for the animated text */}
        </div>
    )
}

export default Jibjib
