import React from 'react'
import Jibjib from './Jibjib'
function Header() {
    return (
        <div className="flex flex-col w-full justifyitem-center items-center mt-20 select-none">
            <img
                src={process.env.PUBLIC_URL + '/img/chick1.svg'}
                className="absolute drop-shadow-lg w-[400px] rotate-90 -translate-x-72 hover:-translate-x-52 -translate-y-56 md:translate-x-0 md:hover:translate-x-0 md:rotate-180 md:-translate-y-full md:hover:-translate-y-3/4 transition-all duration-500 z-0"
            />
            <Jibjib />
        </div>
    )
}

export default Header
