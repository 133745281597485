import React from 'react'
import { FaCircle } from 'react-icons/fa'
import { useWeb3ModalAccount, useWeb3Modal } from '@web3modal/ethers/react'

function WalletConnect() {
    const { address, chainId, isConnected } = useWeb3ModalAccount()
    const { open } = useWeb3Modal()
    return (
        <div className="w-full flex justify-end">
            <div className="w-[256px] mx-2 text-black font-sans text-lg  flex justify-end">
                {isConnected ? (
                    <button
                        type="button"
                        onClick={() => {
                            open()
                        }}
                        className="px-3 w-fit flex flex-row justify-center items-center border-0 border-black hover:text-[#ffaa00] text-black font-bold mt-2 bg-[#ffaa00] rounded-full cursor-pointer transition-all duration-300 hover:bg-[#ffd476] drop-shadow-md"
                    >
                        {chainId == 97 ? (
                            <>
                                <img
                                    width="20"
                                    className=" aspect-square mr-1 grayscale"
                                    src={
                                        process.env.PUBLIC_URL + '/img/bsc.svg'
                                    }
                                />
                                <span className="text-gray-400">BSC</span>
                            </>
                        ) : (
                            <>
                                <img
                                    width="20"
                                    className=" aspect-square mr-1"
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/jbc-badge.png'
                                    }
                                />
                                JBC
                            </>
                        )}
                        <FaCircle
                            size={18}
                            className="text-green-500 drop-shadow-md mx-2"
                        />
                        {address.slice(0, 4)}...{address.slice(-6)}
                    </button>
                ) : (
                    <button
                        type="button"
                        onClick={() => {
                            open()
                        }}
                        className="flex w-fit flex-row justify-center items-center px-3 border-0 border-black text-black  hover:text-[#ffaa00] font-bold mt-2 bg-[#ffaa00] rounded-full cursor-pointer transition-all hover:bg-[#ffd476] drop-shadow-md duration-300"
                    >
                        <FaCircle
                            size={18}
                            className="text-red-600 drop-shadow-md mx-2"
                        />
                        Connect Wallet
                    </button>
                )}
            </div>
        </div>
    )
}

export default WalletConnect
