import { Header, Footer, WalletConnect, StakePage } from './components'
import {
    createWeb3Modal,
    defaultConfig,
    useWeb3ModalAccount,
} from '@web3modal/ethers/react'
import './App.css'
const projectId = '335eb6f09afdc85ba54190c3711c4e6d'

// 2. Set chains
const chains = [
    {
        chainId: 8899,
        name: 'JIBCHAIN',
        currency: 'JBC',
        explorerUrl: 'https://exp-l1.jibchain.net/',
        rpcUrl: 'https://rpc-l1.jibchain.net',
    },
    {
        chainId: 97,
        name: 'BSCTESTNET',
        currency: 'tBNB',
        explorerUrl: 'https://testnet.bscscan.com/',
        rpcUrl: 'https://bsc-testnet.publicnode.com',
    },
]

const metadata = {
    name: 'JIBJIB',
    description: 'JIB JIB JIB JIB',
    url: 'https://jibjib.fun',
    icons: ['https://avatars.mywebsite.com/'],
}

// 3. Create modal

createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: chains,
    projectId,
    themeMode: 'light',
    themeVariables: {
        '--w3m-color-mix': '#804000',
        '--w3m-color-mix-strength': 10,
        '--w3m-border-radius-master': '2px',
        '--w3m-background-color': '#CECECE',
        '--w3m-accent-color': '#F5841F',
    },
    chainImages: {
        8899: '/img/jbc-badge.png',
        97: '/img/bsc.svg',
    },
    tokens: {
        8899: {
            address: '0x808b32a436de4371f42844052E1eb14ee88B98eA',
        },
    },
})

function App() {
    return (
        <div className="App ">
            <WalletConnect />
            <Header className="z-40" />

            <StakePage />
            <Footer />
        </div>
    )
}

export default App
