import React, { useEffect, useState } from 'react'
import AnimatedNumber from 'animated-number-react'
import StakeProgress from './StakeProgress'
import {
    useWeb3ModalAccount,
    useWeb3Modal,
    useWeb3ModalProvider,
} from '@web3modal/ethers/react'
import { BrowserProvider, Contract, formatUnits, parseUnits } from 'ethers'
import Backdrop from '@mui/material/Backdrop'
import { BiLoaderAlt, BiInfoCircle } from 'react-icons/bi'
import { RiCloseCircleFill } from 'react-icons/ri'

const JIBJIB = process.env.REACT_APP_JIBJIB
const JIBSTAKE = process.env.REACT_APP_JIBSTAKE

const formatValue2 = (value) => `${separator(Number(value).toFixed(2))}`
const formatValue4 = (value) => `${Number(value).toFixed(4)}`
function separator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
const IERC20 = require('../abi/IERC20')
const STAKEABI = require('../abi/STAKEABI')

function Loader() {
    return (
        <button
            type="button"
            className="border-4 flex flex-row items-center justify-center border-[#ffaa00] text-white text-3xl font-bold w-full mt-2 p-1 cursor-default  bg-[#ffd476] rounded-xl transition-all"
        >
            <BiLoaderAlt className="animate-spin mr-2" />
            <span className="animate-pulse">Processing... </span>
        </button>
    )
}

function Stake({ updateTrigger }) {
    const [jibjib, setJibjib] = useState(0)
    const [isProcess, setIsProcess] = useState(false)
    const [error, setError] = useState()
    const [info, setInfo] = useState()
    const [pop, setPop] = useState(false)
    const [months, setMonths] = useState(12)
    const [state, setState] = useState(0)
    const [jjBalance, setJJBalance] = useState(0)
    const [stakeProgress, setStakeProgress] = useState(0)
    const { address, chainId, isConnected } = useWeb3ModalAccount()
    const { walletProvider } = useWeb3ModalProvider()
    const { open } = useWeb3Modal()

    const handleClose = () => {
        setPop(false)
    }
    const handleOpen = () => {
        setPop(true)
    }

    function hasSeenPopUp() {
        return document.cookie
            .split(';')
            .some((item) => item.trim().startsWith('hasSeenPopUp='))
    }

    // Function to set the cookie
    function setPopUpCookie() {
        document.cookie = 'hasSeenPopUp=true; max-age=31536000; path=/' // Expires after 1 year
    }

    // Function to show the popup
    function showPopUp() {
        setPop(true)
        setState(9)
        setPopUpCookie()
    }

    // Main execution

    useEffect(() => {
        getBalance()
        if (!hasSeenPopUp()) {
            showPopUp()
        }

        return () => {}
    }, [address, state])

    async function getBalance() {
        if (isConnected) {
            const ethersProvider = new BrowserProvider(walletProvider)
            try {
                const signer = await ethersProvider.getSigner()
                const JJContract = new Contract(JIBJIB, IERC20, signer)
                const JJBalance = await JJContract.balanceOf(address)
                setJJBalance(formatUnits(JJBalance, 18 + 6))
                return formatUnits(JJBalance, 18 + 6)
            } catch (error) {
                console.error(error)
            }
        }
    }

    async function getJJpprove(_amount, _stakeContract) {
        setStakeProgress(1)
        const JJAmt = parseInt(_amount)
        if (isConnected) {
            const ethersProvider = new BrowserProvider(walletProvider)
            try {
                const signer = await ethersProvider.getSigner()
                const JJContract = new Contract(JIBJIB, IERC20, signer)

                const stakeJJAmount = parseUnits(JJAmt.toString(), 18 + 6)
                const JJBalance = await JJContract.balanceOf(
                    signer.getAddress()
                )

                if (JJBalance >= stakeJJAmount) {
                    const currentAllowance = await JJContract.allowance(
                        signer.getAddress(),
                        _stakeContract
                    )
                    const additionalAllowanceNeeded =
                        stakeJJAmount - currentAllowance

                    if (additionalAllowanceNeeded > 0n) {
                        setStakeProgress(2)
                        setInfo('Updating JIBJIB Approval on Metamask')

                        // Increase the allowance
                        const tx = await JJContract.increaseAllowance(
                            _stakeContract,
                            additionalAllowanceNeeded
                        )
                        setInfo('Waiting for block confirmation')
                        await tx.wait(1)
                        setInfo('Confirm Transaction on Metamask')
                        stakeTokens(stakeJJAmount, months)
                    } else {
                        setStakeProgress(2)
                        console.log('Allowance Pass')
                        setInfo('Confirm Transaction on Metamask')
                        stakeTokens(stakeJJAmount, months)
                    }
                } else {
                    console.log('JJBalance', JJBalance)
                    console.log('stakeJJAmount', stakeJJAmount)
                    console.log('Insufficient JIBJIB Balance.')
                    setInfo('')
                    setIsProcess(false)
                    handleClose()
                    setError('Insufficient JIBJIB Balance.')
                    setState(0)
                }
            } catch (err) {
                console.error('Error in getJJApprove:', err)
                setState(0)
                handleClose()
                setIsProcess(false)
            }
        }
    }

    async function stakeTokens(_jibjib, _months) {
        setStakeProgress(3)
        const JJValue = _jibjib.toString()
        if (isConnected) {
            const ethersProvider = new BrowserProvider(walletProvider)

            try {
                const signer = await ethersProvider.getSigner()
                const JSTAKEContract = new Contract(JIBSTAKE, STAKEABI, signer)
                const tx = await JSTAKEContract.stakeTokens(JJValue, _months)

                setInfo('Waiting for a block confirmation')

                const receipt = await tx
                    .wait(1)
                    .then((x) => {
                        updateTrigger()
                        setPop(true)
                        setState(3)
                        setStakeProgress(4)
                        setInfo('Transaction Complete, Happy Staking !')
                        setIsProcess(false)
                    })
                    .catch((error) => {
                        console.log('error code:', error.code)
                        console.error(error)
                        setStakeProgress(0)
                        setIsProcess(false)
                        handleClose()
                    })
                console.log('tx:', tx)
            } catch (error) {
                setStakeProgress(0)
                setIsProcess(false)
                handleClose()
                console.error(error)
            }
        } else {
            setStakeProgress(0)
            console.log('No connected wallet')
        }
    }

    return (
        <div className="flex flex-col w-full justifyitem-center items-center select-none">
            <div className="mt-10 border-4 border-black bg-svg2 md:w-[512px] w-full p-3 flex flex-col justifyitem-center items-center drop-shadow-lg rounded-3xl  bg-neutral-100 bg-opacity-70 backdrop-blur-xl">
                <span className="text-blue-400 font-bold text-sm -mt-6 my-1">
                    <br />
                </span>
                <div className="bg-white font-bold text-2xl rounded-t-2xl py-3 w-full flex flex-row justify-center items-center">
                    Select your Stake Option{' '}
                    <BiInfoCircle
                        onClick={() => {
                            setPop(true)
                            setState(9)
                        }}
                        className="mx-2 cursor-pointer"
                    />
                </div>
                <div className=" w-full flex flex-row justify-between">
                    <div
                        onClick={() => {
                            setMonths(3)
                        }}
                        className="w-1/3 transition-all cursor-pointer bg-white p-1"
                    >
                        <div
                            className={`my-2 flex flex-col justify-start hover:scale-105 transition-all border-2 rounded-lg m-1 ${
                                months == 3
                                    ? 'bg-[#ffaa00] opacity-100 grayscale-0 border-orange-500 drop-shadow-md'
                                    : 'bg-white opacity-50 grayscale'
                            }`}
                        >
                            {' '}
                            <span className=" w-full justify-center text-2xl font-bold">
                                3 Mounths
                            </span>
                            <span className=" text-3xl">3% Yield</span>
                        </div>
                    </div>
                    <div
                        onClick={() => {
                            setMonths(6)
                        }}
                        className="w-1/3 transition-all cursor-pointer bg-white py-1"
                    >
                        <div
                            className={`my-2 flex flex-col justify-start hover:scale-105 transition-all border-2 rounded-lg m-1 ${
                                months == 6
                                    ? 'bg-[#ffaa00] opacity-100 grayscale-0 border-orange-500 drop-shadow-md'
                                    : 'bg-white opacity-50 grayscale'
                            }`}
                        >
                            <span className="w-full justify-center text-2xl font-bold">
                                6 Mounths
                            </span>
                            <span className=" text-3xl">7% Yield</span>
                        </div>
                    </div>
                    <div
                        onClick={() => {
                            setMonths(12)
                        }}
                        className="w-1/3 transition-all cursor-pointer bg-white p-1"
                    >
                        <div
                            className={`my-2 flex flex-col justify-start hover:scale-105 transition-all border-2 rounded-lg m-1 ${
                                months == 12
                                    ? 'bg-[#ffaa00] opacity-100 grayscale-0 border-orange-500 drop-shadow-md'
                                    : 'bg-white opacity-50 grayscale'
                            }`}
                        >
                            {' '}
                            <span className="w-full justify-center text-2xl font-bold">
                                12 Mounths
                            </span>
                            <span className=" text-3xl">15% Yield</span>
                        </div>
                    </div>
                </div>
                <div className="rounded-b-2xl w-full bg-white hover:bg-yellow-100 z-10 transition-all drop-shadow-md">
                    <div
                        className={`w-full flex justify-between font-bold ${
                            jjBalance >= 10 ? 'text-gray-500' : 'text-red-500'
                        }`}
                    >
                        <span className=" text-xl p-3">
                            Your JIBJIB Balance
                        </span>{' '}
                        <span className="p-3 text-xl  flex flex-row ">
                            <AnimatedNumber
                                className="mx-1"
                                value={jjBalance}
                                formatValue={
                                    jjBalance >= 10
                                        ? formatValue2
                                        : formatValue4
                                }
                                duration="500"
                            />
                            M
                        </span>
                    </div>
                    <div className=" w-full flex justify-between -mt-7">
                        <span className="text-black text-xl p-3">
                            JIBJIB to be locked
                        </span>{' '}
                        <span className="p-3 text-xl font-bold text-black md:hidden flex flex-row ">
                            <div className="aspect-square">
                                <img
                                    width="24"
                                    className=" mr-2  "
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/coins/jibjibcoin.png'
                                    }
                                />
                            </div>
                            JIBJIB
                        </span>
                    </div>
                    <div className="w-full flex justify-between -mt-5">
                        <div className="w-full flex justify-between items-center">
                            <span className="p-3 text-5xl font-bold text-black md:flex flex-row hidden">
                                <div className="aspect-square">
                                    {' '}
                                    <img
                                        width="40"
                                        className=" aspect-square mr-2"
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/coins/jibjibcoin.png'
                                        }
                                    />
                                </div>
                                <div>JIBJIB</div>
                            </span>
                        </div>
                        <input
                            placeholder="JIBJIB"
                            type="text"
                            pattern="[0-9]*"
                            min="400"
                            step="1"
                            id="jibjib"
                            keyboardtype="decimal-pad"
                            value={jibjib}
                            autoComplete="off"
                            onChange={(e) => {
                                const re = /^[0-9\b]+$/
                                if (
                                    e.target.value === '' ||
                                    re.test(e.target.value)
                                ) {
                                    setJibjib(e.target.value)
                                }
                            }}
                            className="border-2 text-right my-2 w-full tracking-widest active:outline-none rounded-sm pr-10 outline-non bg-transparent text-black border-none text-5xl font-bold focus:outline-none"
                        />
                        <RiCloseCircleFill
                            className="absolute right-5 text-[#ffaa00] hover:text-red-500 hover:cursor-pointer"
                            fontSize={18}
                            onClick={() => {
                                setJibjib(0)
                            }}
                        />
                        <span className="text-4xl font-bold -ml-9 mt-4 mr-4">
                            M
                        </span>
                    </div>
                </div>

                {isProcess ? (
                    <Loader />
                ) : isConnected ? (
                    <button
                        type="button"
                        onClick={() => {
                            if (jibjib < 10) {
                                setError('10M JIBJIB Minimum Stake required')
                            } else if (chainId == 8899 || chainId == 97) {
                                setInfo(
                                    'Waiting for your confirmation on Metamask'
                                )

                                handleOpen()
                                setState(1)
                                setIsProcess(true)
                                setError('')
                            } else {
                                setError(
                                    'Wrong Network! Please connect to JIBCHAIN'
                                )
                            }
                        }}
                        className="border-4 border-black text-white hover:text-[#ffaa00] hover:text-4xl text-3xl font-bold w-full mt-2 p-1  bg-[#ffaa00] rounded-xl cursor-pointer transition-all hover:bg-[#ffd476] drop-shadow-md"
                    >
                        Stake!
                    </button>
                ) : (
                    <button
                        type="button"
                        onClick={() => {
                            open()
                        }}
                        className="border-4 border-black text-white hover:text-[#ffaa00] hover:text-4xl text-3xl font-bold w-full mt-2 p-1  bg-[#ffaa00] rounded-xl cursor-pointer transition-all hover:bg-[#ffd476] drop-shadow-md"
                    >
                        Connect Wallet
                    </button>
                )}
                <span className="text-red-600 font-bold text-xl mt-1 -mb-2">
                    {error}
                </span>
            </div>

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={pop}
                className="flex flex-col"
            >
                <div className="bg-white  max-h-[70vh] flex flex-col justify-center items-center m-6 text-center text-gray-900 text-lg p-3 rounded-lg md:w-[512px] w-full display-linebreak">
                    <span className="absolute text-black/5">#{state}</span>
                    {state == 1 ? ( // Summary
                        <>
                            <div className="my-5 text-3xl font-bold p-2">
                                Your{' '}
                                <span className="text-blue-500">
                                    {' '}
                                    {formatValue2(jibjib * 10 ** 6)} JIBJIB{' '}
                                </span>{' '}
                                will be loceked <br /> for{' '}
                                <span className="text-red-500">
                                    {months * 30} days{' '}
                                </span>
                                then you will receive <br />
                                <span className="text-blue-500">
                                    {months == 3
                                        ? formatValue2(
                                              jibjib * 10 ** 6 +
                                                  jibjib * 10 ** 6 * 0.03
                                          )
                                        : months == 6
                                        ? formatValue2(
                                              jibjib * 10 ** 6 +
                                                  jibjib * 10 ** 6 * 0.07
                                          )
                                        : formatValue2(
                                              jibjib * 10 ** 6 +
                                                  jibjib * 10 ** 6 * 0.15
                                          )}{' '}
                                    JIBJIB{' '}
                                </span>{' '}
                                in return.
                            </div>
                            <br />
                            <div className="flex flex-row w-full justify-center">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setInfo('Approve your PTC')
                                        setState(2)
                                        getJJpprove(jibjib, JIBSTAKE)
                                    }}
                                    className="w-1/3 border-4 border-black text-white hover:text-[#ffaa00] hover:text-4xl text-3xl font-bold mt-2 p-1  bg-[#ffaa00] rounded-xl cursor-pointer transition-all hover:bg-[#ffd476] drop-shadow-md"
                                >
                                    Continue
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleClose()
                                        setState(0)
                                        setIsProcess(false)
                                    }}
                                    className="w-1/3 mx-2 border-4 border-black text-white hover:text-[#da3c3c] hover:text-4xl text-3xl font-bold mt-2 p-1  bg-[#da3c3c] rounded-xl cursor-pointer transition-all hover:bg-[#f8a2a2] drop-shadow-md"
                                >
                                    Cancel
                                </button>
                            </div>
                        </>
                    ) : null}
                    {state == 2 ? ( //Approve ERC20
                        <>
                            <div className="mt-5 text-3xl font-bold">
                                {info}
                            </div>
                            <StakeProgress step={stakeProgress} />
                            <img
                                width="98"
                                className=""
                                src={
                                    process.env.PUBLIC_URL +
                                    '/img/Infinity-loading.svg'
                                }
                            />
                        </>
                    ) : null}
                    {state == 3 ? ( //Transfer and add stake
                        <>
                            <div className="mt-5 text-3xl font-bold">
                                {info}
                            </div>
                            <StakeProgress step={stakeProgress} />
                            <br />
                            <button
                                type="button"
                                onClick={() => {
                                    handleClose()
                                    setState(0)
                                    setIsProcess(false)
                                }}
                                className="border-4 border-black text-white hover:text-[#ffaa00] hover:text-4xl text-3xl font-bold w-full mt-2 p-1  bg-[#ffaa00] rounded-xl cursor-pointer transition-all hover:bg-[#ffd476] drop-shadow-md"
                            >
                                Ok
                            </button>
                        </>
                    ) : null}

                    {state == 9 ? (
                        <>
                            <div className="mt-5 p-3 flex flex-col items-center text-start text-xl font-sans font-bold overflow-scroll overflow-x-auto">
                                <br />
                                <p className=" text-3xl">Staking!</p>
                                <br />
                                <p className="my-5">
                                    <img
                                        className=""
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/img/stake.png'
                                        }
                                    />
                                </p>
                                <p className="my-5">
                                    1. Lock your JIBJIB tokens to earn yields
                                    (interest).
                                </p>
                                <img
                                    className="scale-100 border-4 rounded-lg border-black"
                                    src={process.env.PUBLIC_URL + '/img/01.png'}
                                />
                                <p className="my-5">
                                    2. Your JIBJIB remains locked during the
                                    staking period.
                                </p>
                                <img
                                    className=""
                                    src={process.env.PUBLIC_URL + '/img/02.png'}
                                />
                                <p className="my-5">
                                    3. After staking ends, you can claim your
                                    original tokens plus interest.
                                </p>
                                <img
                                    className=""
                                    src={process.env.PUBLIC_URL + '/img/03.png'}
                                />
                                <br />
                                <p className="my-5">
                                    4. The longer you stake, the more tokens you
                                    get back as a reward.
                                </p>
                                <br />
                                <img
                                    className=""
                                    src={process.env.PUBLIC_URL + '/img/04.png'}
                                />
                                <br />
                                <p className="my-5">
                                    5. You can stake as much as you want,
                                    anytime.
                                </p>
                                <br />
                                <p className=" text-3xl">Happy Staking!</p>
                                <br />
                            </div>
                            <br />
                            <button
                                type="button"
                                onClick={() => {
                                    handleClose()
                                    setState(0)
                                }}
                                className="border-0 border-black text-white hover:text-[#ffaa00] hover:text-4xl text-3xl font-bold w-full mt-2 p-1  bg-[#ffaa00] rounded-xl cursor-pointer transition-all hover:bg-[#ffd476] drop-shadow-md"
                            >
                                Close
                            </button>
                        </>
                    ) : null}
                </div>
            </Backdrop>
        </div>
    )
}

export default Stake
