import React, { useEffect, useState } from 'react'
import Countdown from 'react-countdown'
import ProgressBar from '@ramonak/react-progress-bar'
import {
    useWeb3ModalAccount,
    useWeb3ModalProvider,
} from '@web3modal/ethers/react'
import { BrowserProvider, Contract, formatUnits } from 'ethers'
import { FaCheck } from 'react-icons/fa'
import { FaRegClock, FaLock } from 'react-icons/fa6'
import Backdrop from '@mui/material/Backdrop'
/* global BigInt */
const JIBSTAKE = process.env.REACT_APP_JIBSTAKE
const STAKEABI = require('../abi/STAKEABI')
const formatValue = (value) => `${separator(Number(value).toFixed(0))}`
function separator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function Stake({
    i,
    amount,
    startTime,
    endTime,
    interestRate,
    isClaimed,
    currentTime,
    claimStake,
}) {
    const totalDuration = endTime - startTime
    const elapsedTime = currentTime - startTime
    const percentageComplete = (elapsedTime / totalDuration) * 100
    return (
        <div
            className={`bg-svg1 font-sans col-span-3 md:col-span-1 bg-white border-2 border-black rounded-lg flex flex-col text-black leading-5 select-none transition-all drop-shadow-lg ${
                isClaimed ? ' opacity-90 grayscale' : 'opacity-100 grayscale-0 '
            }`}
        >
            <div className="w-full flex justify-end p-1">
                <div className="z-50 relative font-bold bg-[#ffaa00]/70 text-lg text-black drop-shadow-md rounded-md px-2 flex flex-row justify-center items-center w-full">
                    {isClaimed ? (
                        <span className="flex flex-row items-center w-fit text-white">
                            <FaCheck className="mr-1" />
                            Claimed
                        </span>
                    ) : endTime - currentTime > 0 ? (
                        <span className="flex flex-row items-center w-fit ">
                            <FaRegClock className=" mr-1" />
                            <Countdown date={endTime * 1000} />
                        </span>
                    ) : (
                        <span className="flex flex-row items-center w-fit text-white">
                            <FaCheck className="mr-1" />
                            Finished
                        </span>
                    )}
                </div>
            </div>
            <div className="w-full bg-white/70 py-3">
                <div className="w-full  text-gray-500 flex flex-row justify-between text-xs ">
                    <span className="mx-4">Capital</span>
                    <span className="mx-4">
                        {formatValue(formatUnits(amount, 18))}
                    </span>
                </div>

                <div className="w-full text-gray-500 flex flex-row justify-between text-xs ">
                    <span className="mx-4">
                        Interest ({parseInt(interestRate)}%)
                    </span>
                    <span className="mx-4">
                        {formatValue(formatUnits(amount * interestRate, 20))}
                    </span>
                </div>

                <div className="w-full flex flex-row justify-between text-sm font-bold">
                    <span className="mx-4">Total</span>
                    <span className="mx-4">
                        {formatValue(
                            formatUnits(
                                amount + (amount * interestRate) / BigInt(100),
                                18
                            )
                        )}
                    </span>
                </div>

                <div className="w-full flex justify-center mt-2">
                    <div className="w-11/12">
                        <ProgressBar
                            completed={percentageComplete}
                            bgColor="#ffaa00"
                            height="13px"
                            borderRadius="3px"
                            isLabelVisible={false}
                            labelColor="#ffaa00"
                            baseBgColor="#d4d4d4"
                            labelSize="8"
                            customLabel=""
                        />
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-center p-1 -mt-2">
                <div className="w-full m-2 text-lg font-bold text-white drop-shadow-sm rounded-md px-1 flex flex-row items-center">
                    {isClaimed ? (
                        <button
                            type="button"
                            onClick={() => {}}
                            className=" text-white w-full mt-2 p-1  bg-[#d4d4d4]  rounded-md cursor-default transition-all"
                        >
                            Completed
                        </button>
                    ) : endTime - currentTime > 0 ? (
                        <button
                            type="button"
                            onClick={() => {}}
                            className=" text-white w-full mt-2 p-1 flex flex-row justify-center items-center bg-[#d4d4d4] rounded-md cursor-default transition-all"
                        >
                            <FaLock className="mx-1" />
                            Locked
                        </button>
                    ) : (
                        <button
                            type="button"
                            onClick={() => {
                                claimStake(i)
                            }}
                            className=" text-white hover:text-[#ffaa00]   w-full mt-2 p-1  bg-[#ffaa00] rounded-md cursor-pointer transition-all hover:bg-[#ffd476] drop-shadow-md"
                        >
                            Claime!
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

function StakeList({ trigger = 0 }) {
    const [stakeArr, setStakeArr] = useState([])
    const [currentTime, setCurrentTime] = useState()
    const [info, setInfo] = useState()
    const [pop, setPop] = useState(false)
    const [update, setUpdate] = useState(0)
    const { address, isConnected } = useWeb3ModalAccount()
    const { walletProvider } = useWeb3ModalProvider()

    useEffect(() => {
        getStakeLength(address)
        getStake(address, 1)
        getStakeList(address)
        setCurrentTime(getBlockTimeStamp())
        return () => {}
    }, [address, pop, trigger, update])

    async function getBlockTimeStamp() {
        if (isConnected) {
            const ethersProvider = new BrowserProvider(walletProvider)
            try {
                const signer = await ethersProvider.getSigner()
                const contract = new Contract(JIBSTAKE, STAKEABI, signer)
                const blockTimeStamp = await contract.getBlockTimeStamp()
                setCurrentTime(parseInt(blockTimeStamp))
                //console.log('blockTimeStamp', parseInt(blockTimeStamp))
                return parseInt(blockTimeStamp)
            } catch (err) {
                console.log('err: ', err)
            }
        }
    }

    async function getStakeLength(_address) {
        if (isConnected) {
            const ethersProvider = new BrowserProvider(walletProvider)
            try {
                const signer = await ethersProvider.getSigner()
                const contract = new Contract(JIBSTAKE, STAKEABI, signer)
                const _stakeLength = await contract.stakeLength(_address)
                //console.log('_stakeLength:', _stakeLength)
                return _stakeLength
            } catch (err) {
                console.log('err: ', err)
            }
        }
    }

    async function getStake(_address, _num) {
        if (isConnected) {
            const ethersProvider = new BrowserProvider(walletProvider)
            try {
                const signer = await ethersProvider.getSigner()
                const contract = new Contract(JIBSTAKE, STAKEABI, signer)
                const _stake = await contract.stakeList(_address, _num)
                //console.log('_stake:', _stake[0])
                return _stake
            } catch (err) {
                console.log('Error : ', err.message, ', Code : ', err.code)
            }
        }
    }

    async function getStakeList(_address) {
        if (isConnected) {
            try {
                let _stakeArr = []
                let _stakeIndex = {}
                for (let i = 0; i < (await getStakeLength(_address)); i++) {
                    let _nftIdToArr = []
                    await getStake(_address, i).then((result) => {
                        //console.log('result:', result)

                        _stakeIndex = {
                            amount: result['amount'],
                            startTime: parseInt(result['startTime']),
                            endTime: parseInt(result['endTime']),
                            interestRate: result['interestRate'],
                            isClaimed: result['isClaimed'] == true,
                        }
                        _stakeArr.push(_stakeIndex)
                    })
                }
                //console.log('_stakeArr:', _stakeArr)
                setStakeArr(_stakeArr)
                return _stakeArr
            } catch (err) {
                console.log('Error : ', err.message, ', Code : ', err.code)
            }
        }
    }

    async function claimStake(_index) {
        if (isConnected) {
            const ethersProvider = new BrowserProvider(walletProvider)
            try {
                setInfo('Confirm Transaction on Metamask')
                setPop(true)
                const signer = await ethersProvider.getSigner()
                const contract = new Contract(JIBSTAKE, STAKEABI, signer)
                const tx = await contract.claimStake(_index)
                setInfo('Waiting for block confirmation')
                const receipt = await tx
                    .wait(1)
                    .then((x) => {
                        setInfo('Transaction Complete!')
                        setTimeout(() => {
                            setPop(false)
                        }, 2000)
                    })
                    .catch((error) => {
                        setPop(false)
                        console.error(error)
                    })
                console.log('tx:', tx)
            } catch (error) {
                setPop(false)
                console.error(error)
            }
        } else {
            setPop(false)
            console.log('No connected wallet')
        }
    }

    return (
        <div className="w-full flex justify-center ">
            {isConnected ? (
                <div className="w-4/5 grid grid-flow-row grid-cols-3 gap-2">
                    <div
                        onClick={() => setUpdate(update + 1)}
                        className="w-full bg-white/30 text-gray-800 text-xl rounded-lg col-span-3 mt-5 drop-shadow-md select-none cursor-pointer"
                    >
                        My Stake ({stakeArr.length})
                        {/* <div className="absolute top-0 right-0 mx-2">
                            Total : {stakeArr.length}
                        </div> */}
                    </div>
                    {[...stakeArr].reverse().map((x, i) => (
                        <Stake
                            i={stakeArr.length - i - 1}
                            key={i}
                            amount={x.amount}
                            startTime={x.startTime}
                            endTime={x.endTime}
                            interestRate={x.interestRate}
                            isClaimed={x.isClaimed}
                            currentTime={currentTime}
                            claimStake={claimStake}
                        />
                    ))}
                </div>
            ) : null}

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={pop}
                className="flex flex-col"
            >
                <div className="bg-white  max-h-[70vh] flex flex-col justify-center items-center m-6 text-center text-gray-900 text-lg p-3 rounded-lg md:w-[512px] w-full display-linebreak">
                    <div className="mt-5 text-3xl font-bold animate-pulse">
                        {info}
                    </div>

                    <img
                        width="98"
                        className=""
                        src={
                            process.env.PUBLIC_URL + '/img/Infinity-loading.svg'
                        }
                    />
                </div>
            </Backdrop>
        </div>
    )
}

export default StakeList
